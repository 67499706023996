/* @tailwind base;
@tailwind components;
@tailwind utilities; */


/* .extra-cell {
    padding: 20px 0 0;
} */

/* back to top */
.back-to-top-button {
	z-index: 400 !important;
}

/* navbar */
 .show {
    background-color: #000A !important;
    color: #000A !important;
 }


.header-nav .nav > li > a {
	padding: 15px 0;
	display: block;
	color: #C1A96E !important;
}


/* banner */

.position {
    position: relative;
}

.animation__main {
    height: 92px;
    overflow: hidden;
    position: absolute;
    top: 32%;
    left: 50%;
    transform: translateX(-50%) !important;
    z-index: 4000;
} 

.pera__size {
    padding-top: 100px;
}

.animation__item {
    font-size: 70px;
    height: 92px;
    line-height: 92px;
}

.text__animation {
    animation: text-animation 8s infinite;
    
}
/* .binance-widget-ticker-wrap {
	background: #231747 !important;
}
.binance-widget-ticker-wrap p {
    color: white !important
} */




@keyframes text-animation {
    0% {
        margin-top: -5px;
    }
    25% {
        margin-top: -114px;
    }
    50% {
        margin-top: -200px;
    }
    75% {
        margin-top: -114px;
    }
    100% {
        margin-top: -5px;
    }
} 

/* responsive */

@media (min-width:300px) and (max-width:766px) { 
    .pera__size {
        padding-top: 30px;
    }
    .animation__main {
        height: 90px;
        overflow: hidden;
    }

    .animation__item {
        font-size: 24px;
        height: 90px;
        line-height: 90px !important;
    }
    .animation__item:first-child {
        font-size: 24px;
        height: 90px;
        line-height: 50px !important;
    }
    .animation__item:nth-child(2) {
        font-size: 24px;
        height: 90px;
        line-height: 70px !important;

    }
    .animation__item:last-child {
        font-size: 24px;
        height: 90px;
        line-height: 50px !important;
    }

    @keyframes text-animation {
        0% {
            margin-top: 0px;
        }
        25% {
            margin-top: -114px;
        }
        50% {
            margin-top: -220px;
        }
        75% {
            margin-top: -114px;
        }
        100% {
            margin-top: 0px;
        }
    } 
    

 } 


@media (max-width: 767.98px) { 
    
    .pera__size {
        padding-top: 30px;
    }

    .animation__main {
        height: 90px;
        overflow: hidden;
    }

    .animation__item {
        font-size: 32px;
        height: 90px;
        line-height: 90px;
    }

    @keyframes text-animation {
        0% {
            margin-top: 0px;
        }
        25% {
            margin-top: -120px;
        }
        50% {
            margin-top: -100px;
        }
        75% {
            margin-top: -120px;
        }
        100% {
            margin-top: 0px;
        }
    }
 }


@media (max-width: 991.98px) { 
    .animation__item {
        font-size: 32px;
        height: 90px;
        line-height: 90px;
    }

    .pera__size {
        padding-top: 30px;
    }

    .animation__main {
        height: 48px;
        overflow: hidden;
    }

    .animation__main {
        top: 35%;

    } 

    @keyframes text-animation {
        0% {
            margin-top: -5px;
        }
        25% {
            margin-top: -114px;
        }
        50% {
            margin-top: -200px;
        }
        75% {
            margin-top: -114px;
        }
        100% {
            margin-top: -5px;
        }
    }
} 


@media (min-width: 1199.98px) { 
    .animation__item {
        font-size: 70px;
        height: 90px;
        line-height: 90px;
    }

    .animation__main {
        height: 90px;
        overflow: hidden;
    }

    @keyframes text-animation {
        0% {
            margin-top: -5px;
        }
        25% {
            margin-top: -114px;
        }
        50% {
            margin-top: -200px;
        }
        75% {
            margin-top: -114px;
        }
        100% {
            margin-top: -5px;
        }
    } 
    

 }
/* @media(min-width: 1399.98px) { 

    
 } */



/* contact */
/* .box {
    width: 30%;
    margin-top: 10px;
    text-align: center;
}
 */


/* cryto widget */
.border__style {
    border-radius: 20px !important;
}